@import "../media/lib";
@import "../type/lib";
@import "lib";

$font-body: $font-body;
$font-family-sans-serif: $font-headline;
$font-family-base: $font-family-sans-serif !default;

$grid-breakpoints: map-merge(("xxs": 0px), $breakpoints);
$grid-gutter-width: $gutter * 2;

$container-max-widths: ();

@each $key, $breakpoint in $breakpoints {
  $side-bar-width-at-container: getSideBarWidthAtBreakpoint($breakpoint);

  @if $breakpoint > 568px {
    $container-max-widths: map-merge($container-max-widths, ($key: $breakpoint - $grid-gutter-width - $side-bar-width-at-container));
  }
}

@import "node_modules/bootstrap/scss/bootstrap";

.container {

  &.container--no-padding {
    padding-left: 0;
    padding-right: 0;

    @each $key, $breakpoint in $breakpoints {

      @media (min-width: $breakpoint) {
        max-width: $breakpoint - $grid-gutter-width * 3;

        &.container--no-padding {
          max-width: $bp - $grid-gutter-width * 3;
        }
      }
    }
  }

  @include breakpoints {
    $side-bar-width-at-container: getSideBarWidthAtBreakpoint($bp);
    max-width: $bp - $grid-gutter-width - $side-bar-width-at-container;
  }
}

.container, .container-fluid {
  padding-left: $gutter;
  padding-right: $gutter;

  @media (max-width: 413px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.wide-container {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;

  @media (min-width:414px) {
    padding-left: $gutter;
    padding-right: $gutter;
  }

  @media (min-width:$sm) {
    padding-left: $gutter * 2;
    padding-right: $gutter * 2;
  }

  @media (min-width:$md) {
    padding-left: $gutter * 3;
    padding-right: $gutter * 3;
  }

  @media (min-width:$lg) {
    padding-left: $gutter * 4;
    padding-right: $gutter * 4;
  }
}

.column-margin {
  margin-top: $grid-gutter-width / 2;
  margin-bottom: $grid-gutter-width / 2;
}

.xwide-gutters {
  @include breakpoints {
    margin-right: -1 * $grid-gutter-width * 1.5;
    margin-left: -1 * $grid-gutter-width * 1.5;

    > .col,
    > [class*="col-"] {
      padding-right: $grid-gutter-width * 1.5;
      padding-left: $grid-gutter-width * 1.5;
    }

    > .column-margin {
      margin-top: $grid-gutter-width * 1.5;
      margin-bottom: $grid-gutter-width * 1.5;
    }
  }
}

.wide-gutters {
  @include breakpoints {
    margin-right: -1 * $grid-gutter-width;
    margin-left: -1 * $grid-gutter-width;

    > .col,
    > [class*="col-"] {
      padding-right: $grid-gutter-width;
      padding-left: $grid-gutter-width;
    }

    > .column-margin {
      margin-top: $grid-gutter-width;
      margin-bottom: $grid-gutter-width;
    }
  }
}

.narrow-gutters {
  @include breakpoints {
    margin-right: -1 * $grid-gutter-width / 4;
    margin-left: -1 * $grid-gutter-width / 4;

    > .col,
    > [class*="col-"] {
      padding-right: $grid-gutter-width / 4;
      padding-left: $grid-gutter-width / 4;
    }

    > .column-margin {
      margin-top: $grid-gutter-width / 4;
      margin-bottom: $grid-gutter-width / 4;
    }
  }
}

.form-row {
  margin-left: -0.5em;
  margin-right: -0.5em;

  > .col,
  > [class*="col-"] {
    padding-right: 0.5em;
    padding-left: 0.5em;
  }

  > .column-margin {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
}
